import {
  getCurrentMonthQuery,
  getSeasonQuery,
  getCategoryPosts,
} from './filters'

const DYNAMIC_MODULES = ['ongoing-events']

const fetchDynamicData = async ($strapi, entity, params = false) => {
  if (!entity) {
    return []
  }
  //params.date_lt = // https://strapi.io/documentation/developer-docs/latest/developer-resources/content-api/content-api.html#api-parameters

  return await $strapi.find(entity, params)
}

const currentMonthEnd = () => {
  const today = new Date()
  const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  return lastDate
}

const loadDynamicDataIntoModule = (
  $strapi,
  content,
  locale,
  store,
  exlude = false,
  include = false
) => {
  return Promise.all(
    content.map(async (module) => {
      if (module?.dynamic) {
        const { entity, query_by, season } = module.dynamic
        let resp = []

        try {
          // load events by date
          if (query_by === 'date' && entity === 'events') {
            resp = await fetchDynamicData(
              $strapi,
              entity,
              getCurrentMonthQuery({
                slug_ne: exlude,
                _locale: locale,
                _sort: 'start_date:ASC',
              })
            )
          }
          // load events by season
          if (query_by === 'season' && season) {
            resp = await fetchDynamicData(
              $strapi,
              entity,
              getSeasonQuery(season, locale)
            )
          }
          // load posts
          if (entity === 'posts') {
            let filters = {
              slug_ne: exlude,
              _locale: locale,
              _limit: 6,
              _sort: 'published_at:DESC',
            }
            if (include?.categories) {
              filters = getCategoryPosts(filters, include.categories)
            }

            resp = await fetchDynamicData($strapi, entity, filters)
          }
          // load news
          if (entity === 'news') {
            let filters = {
              _locale: locale,
              _sort: 'published_at:DESC',
            }

            if(module?.edition) {
              filters = {...filters, 'edition.id': `${module.edition.id}`}
            }

            resp = await fetchDynamicData($strapi, entity, filters)
          }

          if (module.__component === 'modules.ongoing-events') {
            const getEvents = store.getters["settings/getLatestEvents"]
            resp = getEvents(locale)
          }

          if (module.__component === 'modules.featured-items' && entity == 'events') {
            const getEvents = store.getters["settings/getLatestEvents"]
            resp = getEvents(locale)
          }
        } catch (err) {
          resp = [] // continue to render page even if API responds with error.
        }

        module[entity] = resp[entity] ? resp[entity] : resp
      }

      return module
    })
  )
}

export {
  DYNAMIC_MODULES,
  fetchDynamicData,
  currentMonthEnd,
  loadDynamicDataIntoModule,
}
