import qs from 'qs'
import moment from 'moment'

export function getCheckedItem(event) {
  const { id } = event.target
  const divisions = id.split('-')
  return { entity: divisions[0], id: divisions[1] }
}
/**
 * [['categories.name', 'women'], ['categories.name', 'men'], ['_limit', '200']]
 * This is how the filters needs to be passed to the nuxt/strapi in order to be
 * properly added to the query params.
 * @param {object} param
 * @returns
 */
export function appendStoreToParams({
  types,
  pillars,
  categories,
  editions,
  season,
  locale,
  edition,
  sort,
  limit,
  start,
}) {
  const filters = []
  if (types && types.length > 0) {
    filters.push(
      ...types.map((item) => {
        return ['event_types.id', item]
      })
    )
  }
  if (pillars && pillars.length > 0) {
    filters.push(
      ...pillars.map((item) => {
        return ['pillars.id', item]
      })
    )
  }

  if (categories && categories.length > 0) {
    filters.push(
      ...categories.map((item) => {
        return ['post_categories.id', item]
      })
    )
  }

  if (editions && editions.length > 0) {
    filters.push(
      ...editions.map((item) => {
        return ['edition.id', item]
      })
    )
  }

  if (season) {
    filters.push(['season.id', `${season.id}`])
  }
  if (edition) {
    filters.push(['edition.id', `${edition.id}`])
  }
  if (locale) {
    filters.push(['_locale', locale])
  }
  if (sort) {
    filters.push(['_sort', sort])
  }
  if (start) {
    filters.push(['_start', start])
  }
  if (limit) {
    filters.push(['_limit', limit])
  }

  return filters
}
/**
 *  Return event for the current month ordered by date
 * @param {object} params
 * @returns
 */
export function getCurrentMonthQuery(params) {
  const today = moment().format('YYYY-MM-DD')
  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD')
  const endOfMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD')

  const query = qs.stringify({
    ...params,
    _where: {
      _or: [
        [
          { start_date_gte: startOfMonth },
          { end_date_gte: today },
          { start_date_lte: endOfMonth },
        ], //inplicit and
      ],
    },
  })

  return query
}
/**
 * Where start date is >= today and season id or
 * events that start before today but are still happening.
 * @param {object} season
 * @returns
 */
export function getSeasonQuery(season, locale) {
  const { id } = season
  const today = moment().format('YYYY-MM-DD')

  const query = qs.stringify({
    _locale: locale,
    _sort: 'start_date:ASC',
    _where: {
      _or: [
        [{ start_date_gte: today }, { season: id }], //inplicit and
        [{ start_date_lte: today }, { end_date_gte: today }, { season: id }],
      ],
    },
  })

  return query
}

/**
 * Get query to fetch posts within one of the categories
 * of the current post.
 *  @param {Object} parmas
 *  @param {Array} categoies
 * @returns
 */
export function getCategoryPosts(params, categoies) {
  const cats = categoies.map((category) => {
    return [{ post_categories: category.id }]
  })

  const query = qs.stringify({
    ...params,
    _where: {
      _or: cats,
    },
  })
  console.log(query)
  return query
}
